<template>
  <h1>Users</h1>
</template>

<script>
export default {
  name: 'Users'
};
</script>

<style>
</style>